<template>
  <main>
    <br ><br>
    <div class="mentions" v-html="contact" />
    <br ><br>
  </main>
</template>
<script>
export default {
  name: "contact",
  data() {
    return {
      contact: window.contact,
    };
  },
};
</script>
